<template>
  <section>
    <template v-if="restrictLinkAccountToMultipleCity()">
      <b-form-row>
        <b-col>
          <b-alert show variant="danger">{{
            DISPLAY_MESSAGES.MULTIPLE_CITIES_LINK_ACCOUNT_ERROR
          }}</b-alert>
        </b-col>
      </b-form-row>
    </template>
    <template v-else>
      <b-form-row class="my-4 justify-content-between">
        <b-col lg="7" sm="7" cols="12">
          <div class="floating-input-field">
            <b-form-input
              id="form-input-search-link-accounts"
              class="mb-2 mb-sm-1"
              v-model="filter"
              placeholder=" "
              type="text"
            ></b-form-input>
            <label for="form-input-search-link-accounts"
              >Search link accounts <BIconSearch class="input-search-icon"
            /></label>
          </div>
        </b-col>
        <b-col lg="2" sm="5" cols="12" class="mb-2 mb-sm-1">
          <b-dropdown class="action-dropdown" toggle-class="text-center" variant="primary" no-caret right>
            <template #button-content>
              Add link account <BIconPlus scale="1.5" />
            </template>
            <b-dropdown-item
              href="javascript:void(0)"
              @click="navigate('add-link-account')"
              >Link account</b-dropdown-item
            >
            <b-dropdown-item
              href="javascript:void(0)"
              @click="navigate('add-external-link-account')"
              >External link account</b-dropdown-item
            >
          </b-dropdown>
        </b-col>
      </b-form-row>
      <!-- Link Accounts table element -->
      <b-table
        :items="linkAccounts"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @row-dblclicked="onLinkAccountRowDblclicked"
        show-empty
        @filtered="onFiltered"
        hover
        class="table-transition"
        id="link-accounts-table"
        primary-key="accountName"
        :tbody-transition-props="transProps"
        :busy="linkAccountsLoading"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Loading...</strong>
          </div>
        </template>
        <template #cell(noOfFields)="row">
          {{ row.item.fields.length }}
        </template>
        <template #cell(actions)="row">
          <b-dropdown
            class="action-dropdown"
            no-caret
            variant="outline-secondary"
            right
          >
            <template #button-content>
              Actions
              <BIconChevronDown class="pt-1" variant="dark" scale="0.65" />
            </template>
            <b-dropdown-item
              :to="
                row.item.authenticationType == 'external-link-account'
                  ? `/settings/settings-categories/edit-external-link-account/${row.item.id}`
                  : `/settings/settings-categories/edit-link-account/${row.item.id}`
              "
              >Edit</b-dropdown-item
            >
            <b-dropdown-item
              href="javascript:void(0)"
              @click="deleteLinkAccountConfirmDailog(row.item)"
              >Delete</b-dropdown-item
            >
          </b-dropdown>
        </template>
      </b-table>
      <!-- Table options-->
      <b-form-row>
        <b-col sm="6" md="6" lg="3" cols="12" class="my-1">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="5"
            label-cols-md="6"
            label-cols-lg="4"
            label-cols-xl="3"
            label-cols="3"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col lg="6" class="d-none d-lg-block"></b-col>
        <b-col sm="6" md="6" lg="3" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-form-row>
    </template>
    <ConfirmationModal
      :showModal="deleteLinkAccountConfirmModal"
      :title="`CONFIRM`"
      :message="`DELETE_LINK_ACCOUNT`"
      @onConfirm="deleteLinkAccount"
      @closeConfirmModal="deleteLinkAccountConfirmModal = false"
    />
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { BIconPlus, BIconSearch, BIconChevronDown } from 'bootstrap-vue'
import { DISPLAY_MESSAGES } from '../../utilities/constants'
export default {
  name: 'LinkAccounts',
  data () {
    return {
      DISPLAY_MESSAGES: DISPLAY_MESSAGES,
      transProps: {
        // Transition name
        name: 'flip-list'
      },
      linkAccountsLoading: false,
      selectedLinkAccount: null,
      deleteLinkAccountConfirmModal: false,
      fields: [
        {
          key: 'accountName',
          label: 'Link account name',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'authenticationType',
          label: 'Authentication type',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'authEndPoint',
          label: 'End point',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'noOfFields',
          label: 'No. of Fields',
          sortable: true
        },
        { key: 'actions', label: '' }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, 25],
      sortBy: 'linkAccountName',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: ['accountName', 'authEndPoint']
    }
  },
  components: {
    BIconPlus,
    BIconSearch,
    BIconChevronDown,
    ConfirmationModal: () => import('../common/ConfirmationModal.vue')
  },
  computed: {
    ...mapState({
      linkAccounts: (state) => state.settings.linkAccounts,
      selectedTenant: (state) => state.common.selectedTenant
    })
  },
  mounted () {
    this.getLinkAccounts()
  },
  created () {},
  methods: {
    restrictLinkAccountToMultipleCity () {
      return (
        this.selectedTenant.length > 1 ||
        this.selectedTenant.some((tenant) => tenant.tenantID === 0)
      )
    },
    navigate (url) {
      this.$router.push(url).catch(() => {})
    },
    onLinkAccountRowDblclicked (item) {
      const url =
        item.authenticationType === 'external-link-account'
          ? 'edit-external-link-account'
          : 'edit-link-account'
      this.navigate(`${url}/${item.id}`)
    },
    getLinkAccounts () {
      if (
        this.selectedTenant.length &&
        !this.restrictLinkAccountToMultipleCity()
      ) {
        this.linkAccountsLoading = true
        this.$store
          .dispatch('settings/getLinkAccounts')
          .then((response) => {
            this.linkAccountsLoading = false
            if (response.data) {
              this.totalRows = response.data ? response.data.length : 0
            }
          })
          .catch((erro) => {
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: 'LINK_ACCOUNTS_LIST_ERROR',
              type: 'danger'
            })
            this.linkAccountsLoading = false
            this.totalRows = 0
          })
      }
    },
    deleteLinkAccountConfirmDailog (item) {
      this.selectedLinkAccount = item
      this.deleteLinkAccountConfirmModal = true
    },
    deleteLinkAccount () {
      const postObj = {
        linkAccountId: this.selectedLinkAccount.id,
        authenticationType: this.selectedLinkAccount.authenticationType
      }
      this.$store
        .dispatch('settings/deleteLinkAccount', postObj)
        .then((response) => {
          this.deleteLinkAccountConfirmModal = false
          if (response.data && Object.hasOwn(response.data, 'errorMessage')) {
            this.$store.commit('common/setCustomToastData', {
              message: response.data.errorMessage,
              key: false,
              type: 'danger'
            })
          } else if (response.data) {
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: 'DELETE_LINK_ACCOUNT',
              type: 'success'
            })
            this.getLinkAccounts()
          } else {
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: 'FAILED',
              type: 'danger'
            })
          }
        })
        .catch(() => {
          this.deleteLinkAccountConfirmModal = false
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'FAILED',
            type: 'danger'
          })
        })
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  },
  watch: {
    selectedTenant (newValue, oldValue) {
      this.getLinkAccounts()
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
